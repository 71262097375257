const getImageBase64 = async (url: string): Promise<string> => {
  const data = await fetch(url)
  const blob = await data.blob()
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onloadend = () => {
      console.log(reader.result)
      const base64data = (reader.result as string).split('base64,')[1]
      console.log({ base64data })
      resolve(base64data)
    }
    reader.onerror = reject
  })
}

export default getImageBase64

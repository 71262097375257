import { IntlShape } from 'react-intl'

export class ConnectionTimeoutError extends Error {
  static statusCode = 504
  static message = 'connection timeout'
  static formatMessage = (intl: IntlShape) => formatMessageFunc(intl)

  constructor() {
    super()
    Object.setPrototypeOf(this, ConnectionTimeoutError.prototype)
  }

  formatMessage = (intl: IntlShape) => formatMessageFunc(intl)
}

const formatMessageFunc = (intl: IntlShape) =>
  intl.formatMessage({
    defaultMessage: 'Server is busy, please try again later.',
    id: 'kTvuRb',
    description: 'error',
  })

import * as Sentry from '@sentry/react'
import { BadGatewayError } from 'api/errors/BadGateway'
import { ConnectionTimeoutError } from 'api/errors/ConnectionTimeout'
import { UnknownError } from 'api/errors/Unknown'
import axios from 'axios'
import { getEnv } from 'env'

const url = getEnv().restApiUrl

interface Props {
  imageId: String
}

export default async function addImage(props: Props) {
  try {
    await axios.post(
      `${url}/workplace/daily_report_template/remove_image`,
      props,
      {
        withCredentials: true,
      }
    )
  } catch (error: any) {
    const e = error.response?.data?.error
    if (e === BadGatewayError.message) {
      throw new BadGatewayError()
    } else if (e === ConnectionTimeoutError.message) {
      throw new ConnectionTimeoutError()
    } else {
      Sentry.captureException(error)
      throw new UnknownError()
    }
  }
}

import { IntlShape } from 'react-intl'

export class UnknownError extends Error {
  static formatMessage = (intl: IntlShape) => formatMessageFunc(intl)

  constructor() {
    super()
    Object.setPrototypeOf(this, UnknownError.prototype)
  }

  formatMessage = (intl: IntlShape) => formatMessageFunc(intl)
}

const formatMessageFunc = (intl: IntlShape) =>
  intl.formatMessage({
    defaultMessage: 'Unknown error.',
    id: 'SRZovJ',
    description: 'error',
  })

import { BusinessWorkplaceDailyReport } from 'gql/types'
import Photo from './_components/Photo'
import sortByIndex from 'helpers/sortByIndex'

interface Props {
  dailyReport: BusinessWorkplaceDailyReport
}

function Photos({ dailyReport }: Props) {
  return (
    <div style={{ flex: 1 }}>
      {dailyReport.images.sort(sortByIndex).map(image => (
        <Photo image={image} />
      ))}
    </div>
  )
}

export default Photos

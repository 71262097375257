import * as Sentry from '@sentry/react'
import { BadGatewayError } from 'api/errors/BadGateway'
import { ConnectionTimeoutError } from 'api/errors/ConnectionTimeout'
import { UnknownError } from 'api/errors/Unknown'
import axios from 'axios'
import { getEnv } from 'env'

const url = getEnv().restApiUrl

interface Props {
  b64Image: String
  filename: String
  employeeId: String
  workplaceId: String
  index: number
  width: number
  height: number
  aspectRatio: number
}

export default async function addImage(props: Props) {
  try {
    const { data } = await axios.post(
      `${url}/workplace/daily_report_template/add_image`,
      props,
      {
        withCredentials: true,
      }
    )
    const imageId = data.dailyReportTemplateImage.id
    console.log({ imageId })
    return imageId
  } catch (error: any) {
    const e = error.response?.data?.error
    if (e === BadGatewayError.message) {
      throw new BadGatewayError()
    } else if (e === ConnectionTimeoutError.message) {
      throw new ConnectionTimeoutError()
    } else {
      Sentry.captureException(error)
      throw new UnknownError()
    }
  }
}

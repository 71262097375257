import { IntlShape } from 'react-intl'

export class BadGatewayError extends Error {
  static statusCode = 502
  static message = 'Bad Gateway'
  static formatMessage = (intl: IntlShape) => formatMessageFunc(intl)

  constructor() {
    super()
    Object.setPrototypeOf(this, BadGatewayError.prototype)
  }

  formatMessage = (intl: IntlShape) => formatMessageFunc(intl)
}

const formatMessageFunc = (intl: IntlShape) =>
  intl.formatMessage({
    defaultMessage:
      'Bad Gateway, please check your network setting or try again later.',
    id: 'ORSE2c',
    description: 'error',
  })

import Dialog from '@mui/material/Dialog'
import { BusinessWorkplaceDailyReportImage } from 'gql/types'
import { useState } from 'react'

interface Props {
  image: BusinessWorkplaceDailyReportImage
}

function Photo({ image }: Props) {
  const [open, setOpen] = useState(false)
  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <img
          style={{
            objectFit: 'contain',
            width: '100%',
            height: '100%',
          }}
          alt="image1"
          src={image.file!}
        />
      </Dialog>
      <div
        style={{
          height: 100,
          position: 'relative',
          border: '1px solid grey',
        }}
        onClick={() => setOpen(true)}
      >
        <img
          style={{
            objectFit: 'contain',
            width: '100%',
            height: '100%',
          }}
          alt="image1"
          src={image.file!}
        />
      </div>
    </>
  )
}

export default Photo

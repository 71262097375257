import Button from '@mui/material/Button'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import DeleteIcon from '@mui/icons-material/Cancel'
import styled from '@mui/system/styled'

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

interface Props {
  disabled: boolean
  removable: boolean
  index: number
  image?: File | string
  onSelect: (image: File) => void
  onRemove: () => void
}

function ImageUploadButton({
  disabled,
  removable,
  index,
  image,
  onRemove,
  onSelect,
}: Props) {
  if (image instanceof File)
    return (
      <div
        style={{ height: 100, position: 'relative', border: '1px solid grey' }}
      >
        <img
          style={{
            objectFit: 'contain',
            width: '100%',
            height: '100%',
          }}
          alt="image1"
          src={URL.createObjectURL(image)}
        />
        {removable && (
          <DeleteIcon
            style={{
              position: 'absolute',
              top: -8,
              right: -8,
              color: 'red',
              cursor: 'pointer',
            }}
            onClick={onRemove}
          />
        )}
      </div>
    )

  if (image)
    return (
      <div
        style={{ height: 100, position: 'relative', border: '1px solid grey' }}
      >
        <img
          style={{
            objectFit: 'contain',
            width: '100%',
            height: '100%',
          }}
          alt="image1"
          src={image}
        />
        {removable && (
          <DeleteIcon
            style={{
              position: 'absolute',
              top: -8,
              right: -8,
              color: 'red',
              cursor: 'pointer',
            }}
            onClick={onRemove}
          />
        )}
      </div>
    )

  return (
    <Button
      disabled={disabled}
      component="label"
      role={undefined}
      variant="outlined"
      tabIndex={-1}
      startIcon={<CloudUploadIcon />}
      style={{
        height: 100,
        fontSize: 14,
        fontWeight: 'bold',
      }}
    >
      範例 #{index}
      <VisuallyHiddenInput
        type="file"
        accept="image/*"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          if (!event.target.files) return
          onSelect(event.target.files[0])
        }}
      />
    </Button>
  )
}

export default ImageUploadButton
